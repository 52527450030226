const bootstrap = require('bootstrap');

// Connect with Us
document.getElementById('connect_with_us_form').addEventListener('submit', function (e) {
    e.preventDefault(); // Prevent the default form submission

    try {
        const form = document.getElementById('connect_with_us_form');
        const formData = new FormData(form);

        fetch('/console/api/inquiries/add_web', {
            method: 'POST',
            body: formData, 
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Success:', data);
            //alert('submitted');
            connectWithUsModal.show();
        })
        .catch(error => {
            //console.error('Error:', error);
            //alert('error');
            connectWithUsErrModal.show();
        });
         
    } catch (error) {
    }
});


document.getElementById('connect_with_us_form_m').addEventListener('submit', function (e) {
    e.preventDefault(); // Prevent the default form submission

    try {
        const form = document.getElementById('connect_with_us_form_m');
        const formData = new FormData(form);

        fetch('/console/api/inquiries/add_web', {
            method: 'POST',
            body: formData, 
        })
        .then(response => response.json())
        .then(data => {
            //console.log('Success:', data);
            //alert('submitted');
            connectWithUsModal.show();
        })
        .catch(error => {
            //console.error('Error:', error);
            //alert('error');
            connectWithUsErrModal.show();
        });
    } catch (error) {
    }
});

var connectWithUsModal = new bootstrap.Modal(document.getElementById('connectWithUsModal'), {
    backdrop: 'static', // This makes the backdrop static
    keyboard: false     // Prevent modal from closing when Esc is pressed
});
var connectWithUsErrModal = new bootstrap.Modal(document.getElementById('connectWithUsErrModal'), {
    backdrop: 'static', // This makes the backdrop static
    keyboard: false     // Prevent modal from closing when Esc is pressed
});